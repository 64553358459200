import React, { Suspense} from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
const loading = (
  <div className="preloader flex-column justify-content-center align-items-center">
    <img className="animation__shake" src={require("./assets/images/AdminLTELogo.png")} alt="AdminLTELogo" height="60" width="60" />
  </div>
)
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./screens/users/Login'))
function App() {

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
        <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
