import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LoginState {
    isLoggedIn: boolean;
}
const initialState: LoginState = {
    isLoggedIn: false
}

export const loginSlice = createSlice({
    name: 'isLoggedIn',
    initialState,
    reducers: {
      setLoggedIn: (state, action: PayloadAction<boolean>)=>{
         state.isLoggedIn = action.payload;
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setLoggedIn } = loginSlice.actions
  
  export default loginSlice.reducer;