import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface TitleState {
    text: string
  }
  
  const initialState: TitleState = {
    text: "NQS"
  }
  
  export const titleSlice = createSlice({
    name: 'titleSlide',
    initialState,
    reducers: {
      setTitle: (state, action: PayloadAction<TitleState>)=>{
         return {...state, ...action.payload};
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setTitle } = titleSlice.actions
  
  export default titleSlice.reducer;