import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface CounterState {
    id: number,
    name: string
  }
  
  const initialState: CounterState = {
    id: 0,
    name: "kaka"
  }
  
  export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
      setUser: (state, action: PayloadAction<CounterState>)=>{
         return {...state, ...action.payload};
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setUser } = counterSlice.actions
  
  export default counterSlice.reducer;