import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Profile, { MenuModel } from "../../models/users/Profile";

export interface UserProfileState {
  fullName: string,
  pages: string[],
  extFunc: string[],
  menu: MenuModel[],
  assetUrl: string,
  mainUrl: string,
  position: number,
}
const initialState: UserProfileState = {
  fullName: "",
  pages: [],
  extFunc: [],
  menu: [],
  assetUrl: "",
  mainUrl: "",
  position: 0,
}

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<Profile>) => {
      state.fullName = action.payload.FullName;
      state.pages = action.payload.Pages;
      state.extFunc = action.payload.ExtFunc;
      state.menu = action.payload.Menu;
      state.assetUrl = action.payload.AssetUrl;
      state.mainUrl = action.payload.MainDomain;
      state.position = action.payload.Position;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserProfile } = userProfileSlice.actions

export default userProfileSlice.reducer;