import { configureStore } from '@reduxjs/toolkit'
import { counterSlice } from './Slide/CounterSlide';
import { loginSlice } from './Slide/LoginState';
import { userProfileSlice } from './Slide/UserProfileState';
import { titleSlice } from './Slide/TitleSlide';

const store = configureStore({
    reducer: {
        counter: counterSlice.reducer,
        loggedIn: loginSlice.reducer,
        userProfile: userProfileSlice.reducer,
        title: titleSlice.reducer
    },
})
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;